.day-filter {
  border: unset;
  margin-top: 0;
  max-width: unset;
  padding-left: unset;

  > p {
    color: var(--TextIconPrimary);
    font-size: 1.625rem;
    font-weight: 800;
    line-height: 2rem;

    + span {
      margin-left: 0;
    }
  }
}
